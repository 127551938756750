import useCurrentUser from "@/hooks/useCurrentUser";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";

export const DateOfBirth = observer(({ onValidChange }) => {
  // Get the current user
  const { currentUser } = useCurrentUser();

  //
  const initializeDate = () => {
    if (currentUser && currentUser.dateOfBirth) {
      const dateOfBirth = dayjs(currentUser.dateOfBirth);
      return {
        day: dateOfBirth.date().toString(),
        month: (dateOfBirth.month() + 1).toString(),
        year: dateOfBirth.year().toString(),
      };
    }
    return { day: "", month: "", year: "" };
  };

  // State to store the user date of birth
  const [date, setDate] = useState(initializeDate);

  // We need to validate the date before setting the date on the currentUser object
  const [valid, setValid] = useState(false);

  // Make some assumptions about the minimum and maximum years for the date of birth
  const currentYear = new Date().getFullYear();
  const oldestYear = currentYear - 100;
  const youngestYear = currentYear - 12; // TODO: should users be 18 to sign up??!

  // Validate the date, using useCallback to avoid re-creating the function on each render
  const validateDate = useCallback((day, month, year) => {
    // NB: The user might just enter single digits, so don't check for length of the numbers

    // Make sure we have a complete date
    if (!day || !month || !year) return false;

    const date = new Date(year, month - 1, day); // month is 0-indexed in JS Date

    const isValidDate =
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() === parseInt(month, 10) - 1 &&
      date.getDate() === parseInt(day, 10);

    const isBeforeToday = date < new Date();

    return isValidDate && isBeforeToday;
  }, []);

  // Handle changes to the date fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    setDate((prev) => {
      const newDate = { ...prev, [name]: value }; // only update the field that has changed
      return newDate;
    });
  };

  // Validate the date once it has changed
  useEffect(() => {
    const { day, month, year } = date;
    const isValid = validateDate(day, month, year);

    setValid(isValid);
    onValidChange(isValid);
  }, [date, validateDate, onValidChange]);

  // When the data is valid, we can set the dateOfBirth of the currentUser, otherwise set it to null
  // TODO: really we can do this in the validate useEffect, but let's break it out for now for clarity
  useEffect(() => {
    // Bail out if there's no currentUser yet
    if (!currentUser) return;

    if (valid) {
      // Turn the date into a string which is acceptable by Craft using dayjs
      const dateString = `${date.year}-${date.month}-${date.day}`;
      const formattedDate = dayjs(dateString).format("YYYY-MM-DD");

      currentUser.setDateOfBirth(formattedDate);
    } else {
      currentUser.setDateOfBirth(null);
    }
  }, [currentUser, date, valid]);

  // Create an array of fields to render, with some min/max values for client validation to avoid repeating the same JSX
  const fields = [
    {
      name: "day",
      placeholder: "DD",
      min: 1,
      max: 31,
    },
    {
      name: "month",
      placeholder: "MM",
      min: 1,
      max: 12,
    },
    {
      name: "year",
      placeholder: "YYYY",
      min: oldestYear,
      max: youngestYear,
    },
  ];

  return (
    <div className={clsx("relative flex flex-col space-y-1.5")}>
      <label className={clsx("field-label")} htmlFor={fields[0].name}>
        Date of birth
      </label>
      <fieldset className="flex space-x-2">
        {fields.map((field) => (
          <input
            key={field.name}
            type="number"
            inputMode="numeric"
            id={field.name}
            name={field.name}
            placeholder={field.placeholder}
            value={date[field.name]}
            onChange={handleChange}
            className={clsx(
              "text-field-dark min-w-18 appearance-none tabular-nums md:max-w-24",
              "[-moz-appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
            )}
            aria-label={`Enter ${field.name} of birth`}
            min={field.min}
            max={field.max}
          />
        ))}
      </fieldset>
    </div>
  );
});
