import clsx from "clsx";

const PreferredCategoriesTab = ({ label, isSelected, onClick }) => {
  return (
    <button
      className={clsx(
        "objective-tab",
        {
          "objective-tab-selected": isSelected,
        },
        "font-body"
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default PreferredCategoriesTab;
