import { useAuthContext } from "@/contexts/AuthContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

export const WhereDidYouHearAboutUs = observer(({ referralSources = [] }) => {
  const { currentUser } = useCurrentUser();

  const { updateProgress, otherText, setOtherText } = useAuthContext();

  const handleChange = (id) => {
    // If id is null, set referralSources to an empty array
    if (id === null) {
      currentUser.setReferralSources([]);
      return;
    }

    // We need to set currentUser.dietaryRequirementId here
    currentUser.setReferralSources([referralSources.find((s) => s.id === id)]);
  };

  // handling other option
  const handleOtherTextChange = (e) => {
    currentUser.setReferralSourceOther(e.target.value);
    setOtherText(e.target.value);
  };

  useEffect(() => {
    const otherReferralSourceSelected = referralSources.find(
      (referralSource) => referralSource.slug === "other"
    );

    const otherReferralSourceId = otherReferralSourceSelected
      ? otherReferralSourceSelected.id
      : null;

    const isAnyReferralSourceSelected = currentUser.referralSources.length > 0;

    const isOtherReferralSourceSelected =
      otherReferralSourceId &&
      currentUser.referralSourcesIds.includes(otherReferralSourceId);

    const isOtherReferralSourceValid =
      !isOtherReferralSourceSelected ||
      (isOtherReferralSourceSelected && otherText.trim() !== "");

    const isReferralSourceValid =
      isAnyReferralSourceSelected && isOtherReferralSourceValid;

    updateProgress("onboardingSource", true, isReferralSourceValid);
  }, [currentUser.referralSources, otherText]);

  const selectedOption =
    currentUser.referralSources.length > 0
      ? currentUser.referralSources[0]
      : null;

  return (
    <div className="flex w-full flex-col">
      <h1 className={clsx("heading-sm--spatial")}>
        Where did you hear about us?
      </h1>

      <div className="mb-5 mt-8 space-y-4">
        {referralSources.map((source) => {
          const checked = selectedOption?.id === source.id;

          return (
            <label key={source.id} className={clsx("selection-option")}>
              <input
                type="radio"
                value={source.id}
                checked={checked}
                onChange={() => handleChange(source.id)}
                className={clsx("radio-selection")}
              />
              <span className="font-body">{source.title}</span>
            </label>
          );
        })}

        {selectedOption?.slug === "other" && (
          <div className="">
            <input
              type="text"
              value={otherText}
              onChange={handleOtherTextChange}
              placeholder="Please specify"
              className="mt-4 w-full rounded-full bg-[#3a3940] px-8 py-2 font-body"
            />
          </div>
        )}
      </div>
    </div>
  );
});
