import { useAuthContext } from "@/contexts/AuthContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ObjectiveTab from "./ObjectiveTab";
import clsx from "clsx";

export const Objectives = observer(({ objectives }) => {
  const { updateProgress, otherObjective, setOtherObjective } =
    useAuthContext();

  const { currentUser } = useCurrentUser();

  const [isOtherSelected, setIsOtherSelected] = useState(otherObjective !== "");

  const handleChange = (objective) => {
    // We need to identify if the id is in the currentUser.objectivesIds array and remove if it is, or add if it isn't
    if (currentUser?.objectivesIds.includes(objective.id)) {
      currentUser.setObjectives([
        ...currentUser.objectives.filter((o) => o.id !== objective.id),
      ]);
    } else {
      currentUser.setObjectives([...currentUser.objectives, objective]);
    }
    if (objective.slug === "other") {
      setIsOtherSelected(!isOtherSelected);
      if (!isOtherSelected) {
        setOtherObjective(otherObjective);
      } else {
        setOtherObjective("");
      }
    }
  };

  const handleOtherChange = (e) => {
    currentUser.setOtherObjective(e.target.value);
    setOtherObjective(e.target.value);
  };

  useEffect(() => {
    const otherObjectiveSelected = objectives.find(
      (objective) => objective.slug === "other"
    );
    const otherId = otherObjectiveSelected ? otherObjectiveSelected.id : null;

    const isAnyObjectiveSelected = currentUser?.objectivesIds.length > 0;
    const isOtherSelected =
      otherId && currentUser?.objectivesIds.includes(otherId);

    const isOtherValid =
      !isOtherSelected || (isOtherSelected && otherObjective.trim() !== "");

    const isObjectiveValid = isAnyObjectiveSelected && isOtherValid;

    updateProgress("onboardingObjective", true, isObjectiveValid);
  }, [currentUser?.objectivesIds, otherObjective]);

  return (
    <div className="flex flex-col">
      <h1 className={clsx("heading-sm--spatial")}>I want to...</h1>
      <p className="mb-8 font-body">Select as many as you like</p>
      <div className="grid grid-cols-2 gap-4">
        {objectives.map((objective) => {
          const isSelected = currentUser?.objectivesIds.includes(objective.id);

          return (
            <ObjectiveTab
              key={objective.id}
              label={objective.title}
              isSelected={isSelected}
              onClick={() => handleChange(objective)}
            />
          );
        })}
      </div>
      {isOtherSelected && (
        <input
          type="text"
          value={otherObjective}
          onChange={handleOtherChange}
          placeholder="Please specify"
          className="mt-4 w-full rounded-full bg-[#3a3940] px-8 py-2 font-body"
        />
      )}
    </div>
  );
});
