import clsx from "clsx";
import Button from "@/components/Button";
import posthog from "posthog-js";
import { pushEvent } from "@/lib/gtm";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

const SubscriptionCards = ({
  prices,
  hasHadATrial,
  couponCode,
  authStore,
  variant,
  displayCurrency,
  currentStepIndex,
  logOnboardingStep,
  otherObjective,
  otherText,
  otherCategory,
}) => {
  if (!prices?.length) {
    return <p>No pricing plans available.</p>;
  }

  const formatAmount = (amount, currency = "GBP") =>
    new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
    }).format(amount);

  const handleSubmit = (e, price, planType, cost) => {
    e.preventDefault();

    posthog.capture("onboarding_plan_selected", {
      userId: authStore.currentUser?.id,
      plan: planType,
      cost,
      variant,
    });

    localStorage.setItem("checkout_frequency", planType);
    localStorage.setItem("checkout_trial", !hasHadATrial ? "true" : "false");
    localStorage.setItem("checkout_advertised_cost", cost.toString());

    pushEvent("checkout_start", {
      frequency: planType,
      advertised_cost: cost,
      trial: !hasHadATrial,
    });

    logOnboardingStep(
      currentStepIndex,
      "onboardingChooseYourPlan",
      authStore.currentUser,
      otherObjective,
      otherText,
      otherCategory
    );

    e.currentTarget.submit();
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        {["yearly", "monthly"].map((planType) => {
          const isYearly = planType === "yearly";
          const price = prices.find((p) => p.plan === planType);
          const crossedPrice = planType === "yearly" ? "£4.99" : null;
          if (!price) return null;

          const cost = price.amount;
          const currency = price.currency || "GBP";

          const heading = isYearly
            ? `${formatAmount(cost / 12, currency)} / Month`
            : `${formatAmount(cost, currency)} / Month`;

          const subheading = isYearly
            ? `Billed as ${formatAmount(cost, currency)} yearly`
            : "";

          return (
            <form
              key={price.id}
              action="/api/plus/checkout"
              method="POST"
              data-rewardful="true"
              onSubmit={(e) => handleSubmit(e, price, planType, cost)}
              className="mx-auto w-full"
            >
              {couponCode && (
                <input type="hidden" name="coupon" value={couponCode} />
              )}
              <input type="hidden" name="priceId" value={price.id} />
              <input
                type="hidden"
                name="nGNndCdFpnDazku"
                value={!hasHadATrial ? "scUmYobrToNvMkY" : "WrmUyxZMkBbxBrU"}
              />
              <input
                type="hidden"
                name="email"
                value={authStore.currentUser?.email}
              />

              <button
                className={clsx(
                  "group relative flex w-full flex-col items-start overflow-hidden rounded-2xl px-8 pb-6 leading-none transition",
                  "bg-white text-zinc-800"
                )}
                type="submit"
              >
                <span className="mt-8 block font-body text-2xl font-normal text-zinc-900 sm:text-3xl">
                  {crossedPrice && (
                    <s className="mr-2 text-grey-500">{crossedPrice}</s>
                  )}
                  <span
                    className={isYearly ? "font-normal text-green-600" : ""}
                  >
                    £{(isYearly ? cost / 12 : cost).toFixed(2)}
                  </span>{" "}
                  / Month
                </span>

                {subheading && (
                  <span className="text-grey-800 mt-1 block font-body text-sm font-normal">
                    {subheading}
                  </span>
                )}

                <div className="mt-4 flex flex-col items-center">
                  <Button theme="light">Start your free trial</Button>
                </div>
              </button>
            </form>
          );
        })}
      </div>

      <div className="mb-6">
        <h3 className="font-body text-base font-normal">What’s Included:</h3>
        <ul className="mt-2 space-y-2">
          {[
            "5-Day Free Trial. Cancel Anytime.",
            "Thousands of delicious, healthy recipes",
            "Build shopping lists easily",
            "Save all your favourite dishes",
          ].map((item, index) => (
            <li key={index} className="flex items-center font-body font-normal">
              <CheckCircleIcon className="mr-2 h-5 w-5 text-[#7AD691]" />
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SubscriptionCards;
