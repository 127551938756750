import PreferredCategoriesTab from "@/components/Auth/Onboarding/PreferredCategories/PreferredCategoriesTab";
import { useAuthContext } from "@/contexts/AuthContext";
import useCurrentUser from "@/hooks/useCurrentUser";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { shuffle } from "shuffle-seed";
import clsx from "clsx";

export const PreferredCategories = observer(({ onboarding }) => {
  const { currentUser } = useCurrentUser();

  const { updateProgress, otherCategory, setOtherCategory } = useAuthContext();

  const [isOtherSelected, setIsOtherSelected] = useState(otherCategory !== "");

  const handleChange = (category) => {
    // The method on the currentUser is determined by the groupHandle of the category
    // for example, if the groupHandle is "cuisines", the method is "setCuisines"
    const methodName = `set${category.groupHandle
      .charAt(0)
      .toUpperCase()}${category.groupHandle.slice(1)}`;

    if (currentUser[`${category.groupHandle}Ids`].includes(category.id)) {
      currentUser[methodName]([
        ...currentUser[category.groupHandle].filter(
          (c) => c.id !== category.id
        ),
      ]);
    } else {
      currentUser[methodName]([...currentUser[category.groupHandle], category]);
    }
    if (category.slug === "other") {
      setIsOtherSelected(!isOtherSelected);
      if (isOtherSelected) {
        setOtherCategory(otherCategory);
      } else {
        setOtherCategory("");
      }
    }
  };

  const handleOtherCategoryChange = (e) => {
    currentUser.setOtherCategory(e.target.value);
    setOtherCategory(e.target.value);
  };

  // The selection of categories is given in oboarding.cuisines, .recipeCategories, .types, and .occasions, so gather them
  const preferredCategories = [
    ...onboarding.cuisines.map((cuisine) => cuisine),
    ...onboarding.recipeCategories.map((recipeCategory) => recipeCategory),
    ...onboarding.types.map((type) => type),
    ...onboarding.occasions.map((occasion) => occasion),
  ];

  // Shuffle the preferredCategories array
  const shuffledPreferredCategories = shuffle(
    preferredCategories,
    currentUser.id
  );

  useEffect(() => {
    const findOtherCategory = onboarding.recipeCategories.find(
      (otherCategory) => otherCategory.slug === "other"
    );

    const findOtherCategoryId = findOtherCategory ? findOtherCategory.id : null;

    const isAnyCategorySelected = currentUser.hasPreferredCategories;

    const isOtherCategorySelected =
      findOtherCategoryId &&
      currentUser.recipeCategoriesIds.includes(findOtherCategoryId);

    const isOtherCategoryValid =
      !isOtherCategorySelected ||
      (isOtherCategorySelected && otherCategory.trim() !== "");

    const isCategoryValid = isAnyCategorySelected && isOtherCategoryValid;

    updateProgress("onboardingCategories", true, isCategoryValid);
  }, [
    currentUser.recipeCategoriesIds,
    currentUser.occasionsIds,
    otherCategory,
  ]);

  return (
    <div className="flex w-full flex-col">
      <h1 className={clsx("heading-sm--spatial")}>I'm interested in...</h1>
      <p className="mb-8 font-body">
        Select the categories that are important to you
      </p>
      <div className="grid grid-cols-2 gap-4">
        {shuffledPreferredCategories.map((category) => {
          const isSelected = currentUser[`${category.groupHandle}Ids`].includes(
            category.id
          );

          return (
            <PreferredCategoriesTab
              key={category.id}
              label={category.title}
              isSelected={isSelected}
              onClick={() => handleChange(category)}
            />
          );
        })}
      </div>
      {isOtherSelected && (
        <input
          type="text"
          value={otherCategory}
          onChange={handleOtherCategoryChange}
          placeholder="Please specify"
          className="mt-4 w-full rounded-full bg-[#3a3940] px-8 py-2 font-body"
        />
      )}
    </div>
  );
});
