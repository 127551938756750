import useCurrentUser from "@/hooks/useCurrentUser";
import clsx from "clsx";
import { observer } from "mobx-react";

const DietaryRequirements = observer(({ dietaryRequirements }) => {
  return (
    <fieldset className={clsx("relative flex flex-col space-y-1.5")}>
      <legend className={clsx("field-label")}>Any dietary requirements?</legend>
      <div className={clsx("grid grid-cols-2 gap-4")}>
        <_RadioOption dietaryRequirement={null} />
        {dietaryRequirements.map((dietaryRequirement) => {
          return (
            <_RadioOption
              key={dietaryRequirement.id}
              dietaryRequirement={dietaryRequirement}
            />
          );
        })}
      </div>
    </fieldset>
  );
});

const _RadioOption = observer(({ dietaryRequirement }) => {
  const { currentUser } = useCurrentUser();

  const handleChange = () => {
    // If id is null, set dietaryRequirements to an empty array
    if (dietaryRequirement === null) {
      currentUser.setDietaryRequirements([]);
      return;
    }

    // We need to set currentUser.dietaryRequirementId here
    currentUser.setDietaryRequirements([dietaryRequirement]);
  };

  const currentUserHasAnyDietaryRequirements =
    currentUser?.dietaryRequirementsIds.length > 0;

  const checked =
    (!dietaryRequirement && !currentUserHasAnyDietaryRequirements) ||
    currentUser?.dietaryRequirementsIds?.includes(dietaryRequirement?.id);

  const id = `dietary-requirement-${dietaryRequirement?.id || "none"}`;

  return (
    <label className={clsx("selection-option")} htmlFor={id}>
      <input
        type="radio"
        id={id}
        name="dietaryRequirement"
        value={dietaryRequirement?.id || null}
        checked={checked}
        onChange={handleChange}
        className={clsx("radio-selection")}
      />
      {dietaryRequirement?.title || "None"}
    </label>
  );
});

export default DietaryRequirements;
