import * as AlertDialog from "@radix-ui/react-alert-dialog";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

export const ConfirmDialog = ({
  open,
  onOpenChange,
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
}) => {
  return (
    <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {open && (
          <AlertDialog.Portal forceMount>
            <AlertDialog.Overlay asChild>
              <motion.div
                className={clsx(
                  "Alert__overlay",
                  "fixed inset-0 z-60 bg-zinc-800 bg-opacity-75"
                )}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              ></motion.div>
            </AlertDialog.Overlay>
            <AlertDialog.Content
              className={clsx(
                "focus:outline-non fixed left-1/2 top-1/2 z-70 max-h-[85vh] w-[90vw] max-w-md -translate-x-1/2 -translate-y-1/2 space-y-4 rounded-2xl bg-zinc-50 p-6"
              )}
            >
              <div className={clsx("space-y-1")}>
                <AlertDialog.Title className={clsx("heading-xs--spatial")}>
                  {title}
                </AlertDialog.Title>
                <AlertDialog.Description className={clsx("body-text-sm")}>
                  {description}
                </AlertDialog.Description>
              </div>
              <div className="flex justify-end space-x-2">
                <AlertDialog.Action asChild>
                  <button
                    className={clsx("button button-outline")}
                    onClick={onCancel}
                  >
                    {cancelLabel}
                  </button>
                </AlertDialog.Action>
                <AlertDialog.Cancel asChild>
                  <button
                    className={clsx("button button-dark")}
                    onClick={onConfirm}
                  >
                    {confirmLabel}
                  </button>
                </AlertDialog.Cancel>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        )}
      </AnimatePresence>
    </AlertDialog.Root>
  );
};
