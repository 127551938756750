import { ConfirmDialog } from "@/components/ConfirmDialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const CloseButton = () => {
  const router = useRouter();
  const [previousUrl, setPreviousUrl] = useState("/");
  const [show, setShow] = useState(router.asPath === "/signup");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setPreviousUrl(sessionStorage.getItem("previousUrl") || "/");
  }, []);

  const handleClose = () => {
    router.push(previousUrl);
  };

  // If the route is /signup, show the button
  useEffect(() => {
    // Listen for route changes
    const handleRouteChange = (url) => {
      if (url === "/signup") {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, []);

  if (!show) return null;

  return (
    <>
      <button
        className={clsx(
          "w-component h-component flex items-center justify-center rounded-full bg-white text-zinc-800 transition ease-out hover:border-zinc-200 hover:bg-zinc-200"
        )}
        onClick={() => setOpen(true)}
      >
        <XMarkIcon className={clsx("h-5 w-5")} />
      </button>
      <ConfirmDialog
        open={open}
        onOpenChange={setOpen}
        title="Are you sure?"
        description="Sign up today to get unlimited access to our recipes, meal plans and so much more."
        onConfirm={handleClose}
        onCancel={() => setOpen(false)}
        confirmLabel="Close"
        cancelLabel="Sign Up"
      />
    </>
  );
};

export default CloseButton;
