import { useAuthContext } from "@/contexts/AuthContext";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { DateOfBirth } from "./DateOfBirth";
import DietaryRequirements from "./DietaryRequirements";
import { observer } from "mobx-react";

export const AboutYou = observer(
  ({
    dietaryRequirements,
  }: {
    dietaryRequirements: { id: number; title: string; slug: string }[];
  }) => {
    const { updateProgress } = useAuthContext();

    // Determine whether the user has completed all parts of this form
    const [valid, setValid] = useState(false);

    const onValidChange = useCallback((value) => {
      setValid(value);
    }, []);

    useEffect(() => {
      updateProgress("onboardingAboutYou", true, valid);
    }, [valid]);

    return (
      <div className={clsx("w-full")}>
        <div className={clsx("mb-6")}>
          <h1 className={clsx("heading-sm--spatial")}>About You</h1>
        </div>
        <div className={clsx("space-y-4")}>
          <DateOfBirth onValidChange={onValidChange} />
          <DietaryRequirements dietaryRequirements={dietaryRequirements} />
        </div>
      </div>
    );
  }
);
