import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { AboutYou } from "@/components/Auth/Onboarding/AboutYou";
import { ChooseYourPlan } from "@/components/Auth/Onboarding/ChooseYourPlan";
import { Objectives } from "@/components/Auth/Onboarding/Objectives";
import { PreferredCategories } from "@/components/Auth/Onboarding/PreferredCategories";
import { WhereDidYouHearAboutUs } from "@/components/Auth/Onboarding/WhereDidYouHearAboutUs";
import { useRouter } from "next/router";
import { useStores } from "@/hooks/useStores";
import Loading from "@/components/Loading";
import { observer } from "mobx-react";
import useGTM from "@/hooks/useGTM";

type Status =
  | "idle"
  | "checking"
  | "exists"
  | "available"
  | "success"
  | "error"
  | "invalid"
  | "onboarding";

type OnboardingStep = {
  key: string;
  component: React.ComponentType;
  isSkippable: boolean;
  hidden?: boolean;
  skip?: boolean;
};

type OnboardingProgress = {
  key: string;
  viewed: boolean;
  completed: boolean;
};

type AuthContextType = {
  displayCurrency?: string;
  authStatus: Status;
  setAuthStatus: (status: Status) => void;
  currentEmail: string;
  setCurrentEmail: (email: string) => void;
  currentStepIndex: number;
  setCurrentStepIndex: React.Dispatch<React.SetStateAction<number>>;
  onboardingSteps: OnboardingStep[];
  onboardingProgress: OnboardingProgress[];
  updateProgress: (key: string, viewed: boolean, completed: boolean) => void;
  preferredCategories;
  setPreferredCategories: React.Dispatch<React.SetStateAction<string[]>>;
  otherCategory: string;
  setOtherCategory: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  otherText: string;
  setOtherText: React.Dispatch<React.SetStateAction<string>>;
  otherObjective: string;
  setOtherObjective: React.Dispatch<React.SetStateAction<string>>;
  logOnboardingStep: (
    currentStepIndex: number,
    currentStepKey: string,
    currentUser: any,
    otherObjective: string,
    otherText: string,
    otherCategory: string
  ) => void;
};

// Provide initial context value
const initialContextValue: AuthContextType = {
  authStatus: "idle",
  setAuthStatus: (status: Status) => {
    return status;
  },
  currentEmail: "",
  setCurrentEmail: (email: string) => {
    return email;
  },
  currentStepIndex: 0,
  setCurrentStepIndex: (index: number) => {
    return index;
  },
  onboardingSteps: [],
  onboardingProgress: [],
  updateProgress: () => {},
  preferredCategories: [],
  setPreferredCategories: () => [],
  otherCategory: "",
  setOtherCategory: () => [],
  selectedOption: "",
  setSelectedOption: () => [],
  otherText: "",
  setOtherText: () => [],
  otherObjective: "",
  setOtherObjective: () => [],
  logOnboardingStep: () => {},
};

// Creating new context
export const AuthContext = createContext<AuthContextType>(initialContextValue);

type AuthProviderProps = {
  children: ReactNode;
};

const _Loading = ({ handleContinue }) => {
  useEffect(() => {
    handleContinue();
  }, []);

  return <Loading />;
};

// Provider component
export const AuthContextProvider = observer(
  ({ children }: AuthProviderProps) => {
    const gtm = useGTM();
    const { authStore } = useStores();
    const router = useRouter();
    const [authStatus, setAuthStatus] = useState<Status>("idle");
    const [currentEmail, setCurrentEmail] = useState<string>("");
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
    const [onboardingProgress, setOnboardingProgress] = useState<
      OnboardingProgress[]
    >([
      {
        key: "onboardingObjective",
        viewed: true,
        completed: false,
      },
      {
        key: "onboardingCategories",
        viewed: true,
        completed: false,
      },
      {
        key: "onboardingAboutYou",
        viewed: true,
        completed: false,
      },
      {
        key: "onboardingSource",
        viewed: true,
        completed: false,
      },
      {
        key: "onboardingChooseYourPlan",
        viewed: true,
        completed: false,
      },
    ]);

    const [displayCurrency, setDisplayCurrency] = useState("");
    const [preferredCategories, setPreferredCategories] = useState<string[]>(
      []
    );
    const [otherCategory, setOtherCategory] = useState<string>("");
    const [selectedOption, setSelectedOption] = useState<string>("");
    const [otherText, setOtherText] = useState<string>("");
    const [otherObjective, setOtherObjective] = useState<string>("");
    const [hasCouponCode, setHasCouponCode] = useState<boolean>(false);

    useEffect(() => {
      if (router.isReady && router.query["displayCurrency"]) {
        setDisplayCurrency(router.query["displayCurrency"] as string);
      }
    }, [router.isReady, router.query]);

    useEffect(() => {
      setHasCouponCode(!!sessionStorage.getItem("couponCode"));
    }, []);

    const hasCompletedOnBoarding =
      authStore.currentUser?.hasCompletedOnboarding;

    const isPremium = authStore.currentUser?.plan === 2;

    const onboardingSteps: OnboardingStep[] = [
      {
        key: "onboardingObjective",
        component: Objectives,
        isSkippable: false,
        skip: hasCompletedOnBoarding,
      },
      {
        key: "onboardingCategories",
        component: PreferredCategories,
        isSkippable: false,
        skip: hasCompletedOnBoarding,
      },
      {
        key: "onboardingAboutYou",
        component: AboutYou,
        isSkippable: false,
        skip: hasCompletedOnBoarding,
      },
      {
        key: "onboardingSource",
        component: WhereDidYouHearAboutUs,
        isSkippable: true,
        skip: hasCompletedOnBoarding,
      },
      {
        key: "onboardingChooseYourPlan",
        component: ChooseYourPlan,
        isSkippable: false,
        hidden: hasCouponCode,
        skip: isPremium,
      },
      {
        key: "loading",
        component: _Loading,
        hidden: true,
        skip: !hasCompletedOnBoarding,
      },
    ];

    const updateProgress = (
      key: string,
      viewed: boolean,
      completed: boolean
    ) => {
      setOnboardingProgress((prevProgress) =>
        prevProgress.map((step) =>
          step.key === key
            ? {
                ...step,
                viewed,
                completed,
              }
            : step
        )
      );
    };

    const logOnboardingStep = (
      currentStepIndex: number,
      currentStepKey: string,
      currentUser: any,
      otherObjective: string,
      otherText: string,
      otherCategory: string
    ) => {
      let stepData = {};

      switch (currentStepKey) {
        case "onboardingObjective":
          stepData = {
            onboardingObjective: [
              ...currentUser.objectives
                .filter((obj) => obj.slug !== "other")
                .map((obj) => obj.title),
              ...(otherObjective
                ? [`onboardingObjectiveOther: ${otherObjective}`]
                : []),
            ],
          };
          break;

        case "onboardingCategories":
          stepData = {
            onboardingCategories: [
              ...currentUser.cuisines.map((c) => c.title),
              ...currentUser.recipeCategories
                .filter((cat) => cat.slug !== "other")
                .map((rc) => rc.title),
              ...currentUser.types.map((t) => t.title),
              ...currentUser.occasions.map((o) => o.title),
            ].concat(
              currentUser.otherCategory
                ? [`onboardingCategoriesOther: ${currentUser.otherCategory}`]
                : []
            ),
          };
          break;

        case "onboardingAboutYou":
          stepData = {
            onboardingDob: currentUser.dateOfBirth
              ? new Date(currentUser.dateOfBirth).toISOString()
              : null,
            onboardingDietary: currentUser.dietaryRequirements.map(
              (dr) => dr.title
            ),
          };
          break;

        case "onboardingSource":
          stepData = {
            onboardingSource: [
              ...currentUser.referralSources
                .filter((ref) => ref.slug !== "other")
                .map((rs) => rs.title),
              ...(currentUser.referralSourceOther
                ? [`onboardingSourceOther: ${currentUser.referralSourceOther}`]
                : []),
            ],
          };
          break;

        case "onboardingChooseYourPlan":
          const selectedPlan = localStorage.getItem("checkout_frequency");
          stepData = {
            onboardingChooseYourPlan: [`selectedPlan:  ${selectedPlan}`],
          };
          break;

        default:
          stepData = { [currentStepKey]: [] };
      }

      gtm.pushEvent("onboarding_step_completed", {
        onboardingStepName: currentStepKey,
        ...stepData,
      });
    };

    return (
      <AuthContext.Provider
        value={{
          displayCurrency,
          authStatus,
          setAuthStatus,
          currentEmail,
          setCurrentEmail,
          currentStepIndex,
          setCurrentStepIndex,
          onboardingSteps,
          onboardingProgress,
          updateProgress,
          preferredCategories,
          setPreferredCategories,
          otherCategory,
          setOtherCategory,
          selectedOption,
          setSelectedOption,
          otherText,
          setOtherText,
          otherObjective,
          setOtherObjective,
          logOnboardingStep,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
);

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }

  return context;
};
